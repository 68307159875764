import request from '@/utils/request'
// 查询所有项目部
export function projectDepartment (parameter) {
  return request({
    url: '/api/back/employmentEmploy/projectDepartment/page',
    method: 'get',
    params: parameter
  })
}
// 新增项目部
export function AddprojectDepartment (parameter) {
  return request({
    url: '/api/back/employmentEmploy/projectDepartment',
    method: 'post',
    data: parameter
  })
}
// 查询项目负责人
export function employmentUserAll (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSystemManagementController/queryMasterAndSubUser',
    method: 'get',
    params: parameter
  })
}

// 根据项目部id分页查询项目
export function employmentEmployProject (parameter) {
  return request({
    url: '/api/back/employmentEmploy/project/page',
    method: 'get',
    params: parameter
  })
}

// 根据项目部id分页查询项目
export function AddemploymentEmployProject (parameter) {
  return request({
    url: '/api/back/employmentEmploy/project',
    method: 'post',
    data: parameter
  })
}

// 根据项目部id分页查询项目
export function employmentEmployProjectDetele (parameter) {
  return request({
    url: '/api/back/employmentEmploy/project',
    method: 'DELETE',
    params: parameter
  })
}

// 项目部和项目信息展示
export function getWorkbenchProject (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/queryProjectDepartmentAndProjectInfoByPaging',
    method: 'get',
    params: {
      ...parameter
    }
  })
}

// 项目新增项目
export function addProject (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/addProject',
    method: 'post',
    data: parameter
  })
}

// 项目修改项目
export function updateProject (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/updateProject',
    method: 'put',
    data: parameter
  })
}

// 新增项目部
export function addProjectDepartment (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/addProjectDepartment',
    method: 'post',
    data: parameter
  })
}

// 修改项目部
export function updateProjectDepartment (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/updateProjectDepartment',
    method: 'put',
    data: parameter
  })
}

// 用工需求模板
export function queryTemplateDemandByPaging (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/TemplateController/getMy',
    method: 'get',
    params: {
      ...parameter
    }
  })
}

// 根据用工需求模板ID删除发布的用工需求模板
export function deleteTemplateDemand (id) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/deleteTemplateDemand',
    method: 'delete',
    params: {
      id
    }
  })
}

// 物料需求模板
export function queryTemplateMaterialByPaging (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/queryTemplateMaterialByPaging',
    method: 'get',
    params: {
      ...parameter
    }
  })
}

// 删除物料需求模板
export function deleteTemplateMaterial (id) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/deleteTemplateMaterial',
    method: 'delete',
    params: {
      id
    }
  })
}
// 物料需求详情
export function DetailTemplateMaterial (id) {
  return request({
    url: `/api/enterprise/employmentEmploy/workbenchController/queryTemplateMaterialById/${id}`,
    method: 'get',
    params: {
      id
    }
  })
}

// 查询用户签约列表
export function userSignList (params) {
  return request({
    url: `/api/sign/continueSignController/userSignList`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 查询用户签约列表
export function labourBatch (dataList) {
  console.log(dataList)
  return request({
    url: `/api/sign/continueSignController/labourBatch`,
    method: 'post',
    data: dataList
  })
}

// 查询用户签约列表
export function userContractList (params) {
  return request({
    url: `/api/sign/continueSignController/userContractList`,
    method: 'get',
    params: {
      ...params
    }
  })
}
