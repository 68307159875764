<template>
  <a-form-model
    class="form__model__wrapper"
    :model="form"
    :label-col="{span:5,offset: 1}"
    :wrapper-col="{span: 18}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="合同编号">
          <a-input v-model="form.contractNumber" placeholder="请输入合同编号" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="项目名称">
          <a-input v-model="form.projectName" placeholder="请输入项目名称" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="项目负责人">
          <a-select v-model="form.projectLeaderId" allowClear placeholder="请选择项目负责人">
            <a-select-option
              v-for="user in userList"
              :key="user.userId"
              :value="user.userId">
              {{ user.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="项目时间">
          <a-range-picker v-model="form.date" value-format="YYYY-MM-DD" />
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <div class="form__btn__box">
          <a-button @click="handleReset" type>重置</a-button>
          <a-button @click="handleSearch" type="primary">查询</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
 import {
    employmentUserAll
  } from '@/api/workbench'
export default {
  name: 'EmploymentSearch',
  data () {
    return {
      form: {},
      contractOption: [],
      userList: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
       employmentUserAll().then(res => {
          this.userList = res.data
        })
      this.contractOption = this.handleGetDict('contractType')
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    handleSearch () {
      this.$emit('headerSearch')
    },
    handleGetForm () {
      const { contractNumber = '', date = ['', ''] } = this.form
      const [projectStartTime, projectEndTime] = date
      const model = {
        ...this.form,
        status: 1,
        contractType: `LWHT${contractNumber}`,
        projectStartTime,
        projectEndTime
      }
      delete model.date
      return model
    }
  }
}
</script>

<style lang="less">
.form__model__wrapper {
  .form__btn__box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
