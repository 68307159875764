<template>
  <div class="contract__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      合同管理
    </div>
    <div class="content">
      <purchaseSearch ref="purchaseForm" :activeKey="activeKey" @headerSearch="handleSearch.call(null, 'purchaseTable')" />
      <a-tabs v-model="activeKey" @change="handleTabChange" type="card">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.label" > </a-tab-pane>
        <a-button type="primary" slot="tabBarExtraContent" @click="handleDown" v-perms="'download'">合同下载</a-button>
      </a-tabs>
      <p-table
        extraHeight="410"
        :scroll="{ y: 100 }"
        ref="purchaseTable"
        :columns="purchaseColumns"
        :sourceData="getDataApi"
        row-key="id"
        :fileName="activeKey == '1' ? '采购合同' : '劳务合同'"
      >
        <template slot="operation" slot-scope="text, row">
          <a-button type="link" @click="handleOpenContract(row.remoteViewUrl)" v-perms="'look'">查看合同</a-button>
          <a-button type="link" @click="handleDownLoad(row.remoteDownloadUrl)" v-perms="'download-item'">下载合同</a-button>
        </template>
      </p-table>
    </div>
  </div>
</template>

<script>
import employmentSearch from './employment-search.vue'
import purchaseSearch from './purchase-form.vue'
import { getContract, getContractByEnter, getContractById, updateStatus, createContract } from '@/api/contract'

export default {
  name: 'PurchasePage',
  components: {
    employmentSearch,
    purchaseSearch
  },
  data () {
    return {
      activeKey: '1'
    }
  },
  computed: {
    tabs () {
      const arr = [{ label: '采购合同', perms: 'cght', key: '1' }, { label: '劳务合同', perms: 'lwht', key: '2' }]
      return arr
    },
    purchaseColumns () {
      return [
        {
          title: '序号',
          width: 60,
          dataIndex: 'index',
          customRender: (t, r, i) => i + 1
        },
        {
          title: '合同编号',
          download: true,
          dataIndex: 'contractNumber'
        },
        ...this.getColumn(),
        {
          title: '合同签订时间',
          dataIndex: 'signDate',
          download: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    getColumn () {
      if (this.activeKey == '1') {
        return [
          {
            title: '订单编号',
            download: true,
            dataIndex: 'orderNumber'
          },
          {
            title: '供应商名称',
            download: true,
            dataIndex: 'bname'
          }
        ]
      }
      return [
        {
          title: '项目名称',
          key: 'projectName',
          download: true,
          dataIndex: 'projectName'
        },
        {
          title: '工人姓名',
          download: true,
          key: 'bname',
          dataIndex: 'bname'
        }
      ]
    },
    handleTabChange (activeKey) {
      this.$refs.purchaseTable.initPage()
    },
    getDataApi (params) {
      const fromModel = this.$refs.purchaseForm.handleGetForm()
      const fun = this.activeKey == '1' ? getContract : getContractByEnter
      return fun({
        ...params,
        ...fromModel,
        ...this.getParams()
      })
    },
    getParams () {
      if (this.activeKey == '1') {
        return {}
      }
      return {
        enterpriseId: this.$store.getters.enterpriseId,
        contractType: 'LWHT'
      }
    },
    handleSearch (tableRef) {
      this.$refs[tableRef].initPage()
    },
    handleOpenContract (path) {
      window.open(path)
    },
    handleDownLoad (path) {
      window.open(path)
    },
    handleDown () {
      this.$refs.purchaseTable.handleFileDown()
    },
    handleOk () {}
  }
}
</script>

<style scoped lang="less">
@import url('./index');
</style>
<style lang="less">
@import url('../../../common/style');
.contract__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}
</style>
