<template>
  <a-form-model
    class="form__model__wrapper"
    :model="form"
    :label-col="{span:5,offset: 1}"
    :wrapper-col="{span: 18}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="合同编号">
          <a-input v-model="form.contractNumber" placeholder="请输入合同编号" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item :label="label">
          <a-input v-model="form.bName" :placeholder="`请输入${label}`" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <!-- <a-col :span="8">
        <a-form-model-item label="采购人">
          <a-select v-model="form.bName" allowClear placeholder="请选择采购人">
            <a-select-option
              v-for="user in userList"
              :key="user.userId"
              :value="user.userId">
              {{ user.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col> -->
      <a-col :span="8">
        <div class="form__btn__box" v-perms="'search'">
          <a-button @click="handleReset" type>重置</a-button>
          <a-button @click="handleSearch" type="primary">查询</a-button>
        </div>
      </a-col>
    </a-row>
    <!-- <a-row>
      <a-col :span="8">
        <a-form-model-item label="签订时间">
          <a-range-picker v-model="form.date" value-format="YYYY-MM-DD" />
        </a-form-model-item>
      </a-col>

    </a-row> -->
  </a-form-model>
</template>

<script>
 import {
    employmentUserAll
  } from '@/api/workbench'
export default {
  name: 'PurchaseSearch',
  props: {
    activeKey: String
  },
  data () {
    return {
      form: {},
      contractOption: [],
      userList: []
    }
  },
  computed: {
    label () {
      return this.activeKey == '1' ? '供应商名称' : '工人名称'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
       employmentUserAll().then(res => {
          this.userList = res.data
        })
      this.contractOption = this.handleGetDict('contractType')
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    handleSearch () {
      this.$emit('headerSearch')
    },
    handleGetForm () {
     return this.form
    }
  }
}
</script>

<style lang="less">
.form__model__wrapper {
  background: #ffffff;
  .form__btn__box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-btn-primary {
      margin-left: 8px;
    }
  }
}
</style>
